<template>    
  <div id="headerNav" class="nav">
    <div class="container" style="position: relative;">
      <div class="nav-logo">        
          <a href="/"><div class="logo background"></div></a>
      </div>
      <div class="header-nav">
        <ul>
          <!-- <li v-for="item in items" class="item.class" :key="item.title">
            <router-link v-if="item.next == null" to="item.to">{{item.title}}</router-link>      
            <ul v-if="item.next != null">
              <li v-for="subitem in item.next" :key="subitem.title">
                {{subitem.title}}
              </li>
            </ul>
          </li> -->
          <li class="nav-item"><router-link to="/">首页</router-link></li>
          <li class="nav-item"><router-link to="/about">关于全澜</router-link></li>
           <li class="nav-item"><router-link to="/news">新闻中心</router-link></li>
          <li class="nav-item">
            <router-link to="/solution">产品与服务</router-link>
            <div class="nav-solution">
                <div class="item link">澜鲸高精度多通道系列
                  <div class="sub-nav">
                    <div class="item link" @click="forwardTo('/research01')">多功能闭环电刺激系统</div>
                    <div class="item link" @click="forwardTo('/researchC64r')">RSC-64R 穿戴式脑电采集器</div>
                  </div>
                </div>
                <div class="item">
                  澜猫多模态无线系列
                  <div class="sub-nav">
                    <div class="item link" @click="forwardTo('/research02')">居家睡眠监测与调控</div>
                    <div class="item link" @click="forwardTo('/research03')">小动物多模态无线电生理监测</div>
                    <div class="item link" @click="forwardTo('/research32')">大动物用无线多通道电刺激-电生理监测</div>
                  </div>
                </div>
                <div class="item">
                  数据分析与手术服务
                  <div class="sub-nav">
                    <div class="item link" @click="forwardTo('/analysis')">数据分析服务</div>
                    <div class="item link" @click="forwardTo('/operation')">手术与手术培训服务</div>
                  </div>
                </div>
            </div>
          </li>
          <!-- <li class="nav-item"><router-link to="/product">产品中心</router-link></li> -->
          <li class="nav-item"><router-link to="/support">服务支持</router-link></li>
          <li class="nav-item"><router-link to="/contact">联系我们</router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

  import { defineComponent } from 'vue';

  export default defineComponent({
    methods: {
      forwardTo(url) {
        window.location.href = url;
      }
    },
  });

  function throttle(func, limit) {
    let inThrottle;
    return function() {
      const args = arguments;
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }

  const throttledScrollHandler = throttle(function() {
    var scrollY = window.pageYOffset || document.documentElement.scrollTop;
    var el = document.getElementById("headerNav");
    if (scrollY > 50) {
      el.classList.add("ennav");
    } else {
      el.classList.remove("ennav");
    }
  }, 10); // 节流时间设置为 100 毫秒

  window.addEventListener('scroll', throttledScrollHandler);
</script>